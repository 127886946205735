// jQuery
jQuery(function($){
		$('.js-accordion').click(function(){
			$(this).find('.js-accordion--contents').slideToggle();
			$(this).toggleClass("is-open");
			var isOpen = $(this).hasClass('is-open');
        if (isOpen) {
            $(this).find('p').text('閉じる');
        } else {
            $(this).find('p').text('続きを読む');
        }
	});
});